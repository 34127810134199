import React, { PureComponent } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import binder from 'helpers/binder';
import { resourcesApp } from 'reducers/app';
import Loading from 'components/Loading';
import { SUCCESS } from 'helpers/constants';
import NotFound from 'containers/Error/E404';
import Form from 'containers/Form';
import User from 'containers/User';
import Log from 'containers/Log';

import Header from './Header';
import styles from './indexStyles';

class App extends PureComponent {
  static propTypes = {
    stateUser: PropTypes.objectOf(PropTypes.any).isRequired,
    stateMain: PropTypes.objectOf(PropTypes.any).isRequired,
    classes: PropTypes.objectOf(PropTypes.any).isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    match: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  componentWillMount() {
    const { actions } = this.props;
    actions.resourcesApp();
    document.getElementById('app').style.backgroundColor = 'white';
  }

  render() {
    const {
      stateUser, stateMain, match, classes,
    } = this.props;
    const isWait = stateMain.get('status') !== SUCCESS;
    if (isWait) {
      return (
        <Loading />
      );
    }

    return (
      <React.Fragment>
        <Header
          user={stateUser}
        />
        <Grid
          container
          wrap="nowrap"
          justify="space-between"
          alignItems="stretch"
          direction="column"
          className={classes.content}
        >
          <Switch>
            <Route path={`${match.url}forms`} component={Form} />
            <Route path={`${match.url}users`} component={User} />
            <Route path={`${match.url}log`} component={Log} />
            <Redirect from="/" to={`${match.url}forms`} />
            <Route component={NotFound} />
          </Switch>
        </Grid>
      </React.Fragment>
    );
  }
}

export default binder({
  styles,
  stylesName: 'AppIndex',
  states: {
    stateMain: ['app', 'resources'],
    stateUser: ['user', 'login', 'data'],
  },
  actions: { resourcesApp },
  component: App,
});
